<!--<image-upload @update:imageUploadCompleted="uploadVehicleImg"></image-upload>-->

<style scoped lang="scss">
.img-selector {

    input {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1000;
        width: 100%;
        height: 100%;
        cursor: pointer;
    }

}

.avatar-uploader .avatar-uploader-icon {
    width: 120px;
    height: 120px;
    line-height: 120px;
}

.avatar-uploader {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 120px;
    height: 120px;
    /*float: left;*/
    /*margin-right: 10px;*/
    .avatar-uploader-hover {
        position: absolute;
        left: 0;
        right: 0;
        top: 120px;
        height: 120px;
        z-index: 1009;
        background: rgba(0, 0, 0, 0.5);
        transition: all 400ms;
        -moz-transition: all 400ms;
        -webkit-transition: all 400ms;

        p {
            color: #319BF7;
            background: #fff;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            line-height: 30px;
            display: inline-block;
            float: left;
        }
    }

    .uploader-hover-cont {
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -15px 0 0 -35px;
        text-align: center;
    }
}

.avatar-uploader:hover {
    .avatar-uploader-hover {
        top: 0;
    }
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

.avatar {
    width: 100%;
    height: 100%;
    display: block;
}

.upload-button {
    position: relative;
    overflow: hidden;
    width: 80px;
    height: 40px;
}

.width600 {
    width: 600px;
}

.image-cont {
    float: left;
    margin-left: 15px;
}

.big-new-img {
    cursor: pointer;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9999
}


</style>
<style lang="scss">
#imgae-upload {
    .el-dialog {
        width: 650px;
    }
}

</style>

<template>
    <div id="imgae-upload">
        <div class="image-cont" v-for="(item,index) in imageList"
             :key="index"
             @dragover="handleDragover(index)"
             @dragover.prevent
             @drop="handleDrop(index)">
            <div class="img-selector avatar-uploader"
                 :draggable="item.imageUrl"
                 @dragstart="handleDragStart(index)"
                 @dragend="handleDragend(index)"
                 :style="{width:width,height:height}" v-loading="loading"
                 v-if="!isButton">
                <form ref="img-selector-f">
                    <input v-if="max==1" @change="selectChange($event, index)" type="file" :accept="accept"
                           :disabled="disabled">
                    <input v-if="max>1" multiple @change="selectChange($event,index)" type="file" :accept="accept"
                           :disabled="disabled">
                    <a :href="item.imageUrl" target="_blank" v-if="disabled&&item.imageUrl"
                       :class="{'big-new-img':disabled && item.imageUrl}" style=""></a>
                </form>
                <div :style="{width:width,height:height}">
                    <div v-if="item.imageUrl&&item.showImage">
                        <!--                        <img :src="item.imageUrl" class="avatar" v-if="!isFilePdf&&item.showImage">-->
                        <!--                        <see-pdf :src="item.imageUrl" v-if="isFilePdf"></see-pdf>-->
                        <look-images
                            :id="'imagesUpload' + index"
                            :imagesUrl="item.imageUrl"
                        ></look-images>
                    </div>
                    <i class="el-icon-plus avatar-uploader-icon"
                       :style="{width:width,height:height, 'line-height': height}" v-else></i>
                </div>
                <div class="avatar-uploader-hover" v-if="item.imageUrl&&!disabled" :style="{width:width,height:height}">
                    <div class="uploader-hover-cont">
                        <p v-if="isCropper" style="margin-right: 10px" @click="cutImg(item.imageUrl,index)"><i
                            class="icon icon-cropper-cut"></i></p>
                        <p @click="delImg(index)"><i class="icon icon-delete"></i></p>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="upload-button img-selector">
                    <form ref="img-selector-f">
                        <input v-if="max==1" @change="selectChange($event,index)" type="file" :accept="accept">
                        <input v-if="max>1" multiple @change="selectChange($event,index)" type="file" :accept="accept">
                    </form>
                    <el-button type="primary">上传</el-button>
                </div>
                <div v-if="fileName">
                    <p>{{ fileName }} <i class="el-icon-circle-close" @click="delImg"></i></p>
                </div>
            </div>
        </div>
        <el-dialog :visible.sync="dialogVisible" center>
            <div style="width: 600px;height: 500px;margin-bottom: 20px;">
                <p style="text-align: center;font-size: 14px;color: #000B15;">编辑封面图片，调整图片尺寸和位置</p>
                <vue-cropper ref="cropper" :img="selectImg" :output-size="option.size" :output-type="option.outputType"
                             :info="true" :full="option.full"
                             :can-move="option.canMove" :can-move-box="option.canMoveBox" :fixed-box="option.fixedBox"
                             :original="option.original"
                             :auto-crop="option.autoCrop" :auto-crop-width="option.autoCropWidth"
                             :auto-crop-height="option.autoCropHeight" :center-box="option.centerBox"
                             @real-time="realTime" :height="option.height" style="min-height: 500px"></vue-cropper>

            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="scaleBtn(-1)">小</el-button>
                <!--<div style="width: 200px;float: left"><el-slider :min="1" :max="1000" v-model="scaleNum"></el-slider></div>-->
                <el-button type="primary" @click="scaleBtn(1)">大</el-button>

                <el-button type="primary" @click="saveCut()">保存</el-button>
            </span>
        </el-dialog>
    </div>


</template>
<script type="text/ecmascript-6">
import {resizeFile} from '@/common/js/imgUtils.js'
import {up2QiNiu} from '@/common/js/up2QinNiu/'
import vueCropper from 'vue-cropper'
// import seePdf from 'vue-pdf'
import lookImages from '../look-images'

export default {
    //定义模版数据
    components: {
        vueCropper,
        // seePdf,
        lookImages
    },
    data() {
        let upImgKey = '/product/' + (+new Date()) + '/',
            count = 0,
            loading = false,
            imageUploadList = [{imageUrl: '', showImage: false}],
            file,
            showImage = false,
            fileName = '',
            selectImg = '',
            scaleNum = 500,
            cutIndex = 0,
            dialogVisible = false;
        return {
            upImgKey,
            count,
            loading,
            imageUploadList,
            file,
            showImage,
            fileName,
            dialogVisible,
            selectImg,
            scaleNum,
            cutIndex
        }
    },
    props: {
        //一次上传张数 默认一张直接返回图片路径 多张则返回图片路径数组
        max: {
            type: Number,
            default: 1
        },
        accept: {
            type: String,
            default: 'image/*'
        },
        isFilePdf: {
            type: Boolean,
            default: false
        },
        isButton: {
            type: Boolean,
            default: false
        },
        isCropper: {
            type: Boolean,
            default: false
        },
        width: {
            type: String,
            default: '120px'
        },
        height: {
            type: String,
            default: '120px'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        option: {
            type: Object,
            default: function () {
                return {
                    size: 1,
                    full: false,
                    outputType: 'jpeg',//输出图片格式
                    canMove: true,//拖动图片
                    fixedBox: true,//截图框固定大小
                    autoCropWidth: 440,//截图框固定大小
                    autoCropHeight: 248,//截图框固定大小
                    autoCrop: true,//自动生成截图狂
                    original: true,
                    canMoveBox: true,//拖动截图框
                    centerBox: true, //截图框限制在图片内
                    height: true
                }
            }
        },
        images: {
            type: Array,
            default: function () {
                return [
                    {
                        imageUrl: '',
                        showImage: false
                    }
                ]
            }
        }
    },
    //计算属性
    computed: {
        imageList() {
            let imgs = this.imageUploadList;
            if (this.images.length > 0) imgs = this.images;
            else imgs = [{imageUrl: '', showImage: false}];
            return imgs;
        }
    },
    //定义事件方法
    methods: {
        selectChange: function (e, index) {
            let files = e.target.files;
            if ((files.length > this.max || this.imageList.length == (this.max + 1) || (files.length + this.imageList.length) > this.max) && index == this.max) {
                this.$message('一次最多上传' + this.max + '张图片');
                return false
            }
            for (let i = 0; i < files.length; i++)
                this.upFile(files[i], -1, files.length)
            this.$refs['img-selector-f'][index].reset();
        },
        upFile: async function (file, index) {
            this.loading = true;
            let extend = ".jpg";
            if (file.name) {
                extend = file.name.split('.')[1];
                this.fileName = file.name;
            }
            let imgName = (+new Date()) + (this.count++) + '.' + extend;
            let resFile = '';
            let isImg = false;
            if (file.type.indexOf('image') != -1) {
                isImg = true;
                resFile = await resizeFile(file);
            }
            if (file.type.indexOf('pdf') != -1) {
                isImg = true;
                resFile = file;
            }
            let res = await up2QiNiu(this, this.upImgKey + imgName, resFile);
            if (this.max == 1) this.imageList[0] = {imageUrl: res, showImage: isImg};
            else {
                if (index == -1)
                    this.imageList.push({imageUrl: res, showImage: isImg});
                else this.imageList[index] = {imageUrl: res, showImage: isImg};

            }
            if (this.imageList.length > this.max) this.imageList.splice(0, 1)
            this.$emit('update:imageUploadCompleted', this.imageList);
            this.loading = false;

        },
        delImg(index) {
            this.fileName = '';
            this.imageList.splice(index, 1);
            if ((this.imageList.length > 0 && this.imageList[0].imageUrl) || this.imageList.length === 0) {
                this.imageList.splice(0, 0, {imageUrl: '', showImage: false})
            }
            this.$forceUpdate();
            this.$emit('update:imageUploadCompleted', this.imageList);
        },
        realTime() {

        },
        cutImg(img, index) {
            this.dialogVisible = true;
            this.selectImg = img;
            this.cutIndex = index;
        },
        saveCut() {
            this.$refs.cropper.getCropBlob((data) => {
                this.upFile(data, this.cutIndex, 1);
                this.dialogVisible = false;
            })
        },
        scaleBtn(val) {
            this.$refs.cropper.changeScale(val)
        },
        //拖动开始
        handleDragStart(index) {
            this.dragIndex = index;
        },
        //拖动结束
        handleDragend() {
            this.dragIndex = null;
        },
        //拖动路过
        handleDragover(index) {
            this.dragoverIndex = index;
        },
        //拖动放下
        handleDrop(index) {
            if (!this.imageList[index].imageUrl)
                return;
            const list = this.imageList;
            const item = list[index];
            list[index] = list[this.dragIndex];
            list[this.dragIndex] = item;
            this.$emit('update:imageUploadCompleted', [...list]);
        }
    },
    //监听模版变量
    watch: {
        scaleNum(n, o) {
            if (n > o) {
                this.$refs.cropper.changeScale(1)
            }

            if (n < o) this.$refs.cropper.changeScale(-1)
        }
    }

}
</script>


